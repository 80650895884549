import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from './common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "release-notes"
    }}>{`Release Notes`}</h1>
    <h2 {...{
      "id": "v150---18072022"
    }}>{`v1.5.0 - `}<inlineCode parentName="h2">{`18.07.2022`}</inlineCode></h2>
    <p>{`Список изменений:`}</p>
    <ol>
      <li parentName="ol">{`Новые компоненты`}
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-calendar"
            }}>{`Calendar`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-range-date-picker"
            }}>{`SingleDatePicker`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-month-picker"
            }}>{`MonthPicker`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-year-picker"
            }}>{`YearPicker`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-single-time-picker"
            }}>{`SingleTimePicker`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-range-date-picker"
            }}>{`RangeDatePicker`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-date-time-picker"
            }}>{`DateTimePicker`}</a></li>
        </ol>
      </li>
    </ol>
    <h2 {...{
      "id": "v140---01072022"
    }}>{`v1.4.0 - `}<inlineCode parentName="h2">{`01.07.2022`}</inlineCode></h2>
    <p>{`Список изменений:`}</p>
    <p>{`Релиз библиотеки компонентов для Vue 3. Документация доступна по ссылке `}<a parentName="p" {...{
        "href": "https://vue3.design-system.hse.ru/"
      }}>{`https://vue3.design-system.hse.ru/`}</a></p>
    <h2 {...{
      "id": "v130---12022022"
    }}>{`v1.3.0 - `}<inlineCode parentName="h2">{`12.02.2022`}</inlineCode></h2>
    <p>{`Список изменений:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Новые компоненты`}</p>
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-sidebar#%D0%BF%D0%BE%D0%B4%D0%BC%D0%B5%D0%BD%D1%8E"
            }}>{`SidebarItemCollapse`}</a>{`. Позволяет создавать вложенные пункты меню в Sidebar`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-side-pane"
            }}>{`SidePane`}</a>{`. Закреплённая боковая панель, содержащая  контекстные элементы, соответствующие текущему контенту и графически отделённая от`}{` `}{`основной области контента`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-mobile-menu"
            }}>{`MobileMenu`}</a>{`. Компонент меню для мобильных устройств`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-button-popover"
            }}>{`ButtonPopover`}</a>{`. Позволяет связывать компоненты Button и Popover`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-link-abbr"
            }}>{`LinkAbbr`}</a>{`. Ссылка с подсказкой. LinkAbbr может использоваться в предложении или абзаце.`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-scroll-area"
            }}>{`ScrollArea`}</a>{`. Компонент для отображения прокручиваемых областей в интерфейсе`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Новые фичи`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Добавили размер Large для компонента Tag`}</li>
          <li parentName="ol">{`Добавили автоопределение иконки для компонента FileButton`}</li>
          <li parentName="ol">{`Добавили проп size компоненту Checkbox`}</li>
        </ol>
      </li>
    </ol>
    <h2 {...{
      "id": "v120---22102021"
    }}>{`v1.2.0 - `}<inlineCode parentName="h2">{`22.10.2021`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "https://gitlab.com/proscom/hse-design-issues"
      }}>{`У нас появился публичный репозиторий`}</a>{` для сообщения об ошибках и запроса нового функционала.`}</p>
    <p>{`Список изменений:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Новые компоненты`}</p>
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-page-layout"
            }}>{`PageLayout`}</a>{`. Позволяет быстро создавать продукты из предзаданных комбинаций Header, Sidebar, TapBar. Компонент автоматически адаптируется под разрешение экрана`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-person"
            }}>{`Person`}</a>{`. Компонент для отображения аватара пользователя. Может использоваться с любыми заданными размерами`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-checkbox/#checkboxgroup"
            }}>{`CheckboxGroup`}</a>{` в React`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-card"
            }}>{`Card`}</a>{` новый компонент`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Новые фичи`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Обновили иконки и логотипы`}</li>
          <li parentName="ol">{`Добавили вывод сообщений в консоль при использовании устаревших пропов`}</li>
          <li parentName="ol">{`В `}<inlineCode parentName="li">{`core`}</inlineCode>{` пакете убрали дублирование стилей. Также доработали `}<inlineCode parentName="li">{`focus-visible`}</inlineCode>{` стили для корректного отображения их в Safari`}</li>
          <li parentName="ol">{`Адаптивность`}
            <ol parentName="li">
              <li parentName="ol">{`Добавили хук `}<inlineCode parentName="li">{`useBreakpoint`}</inlineCode>{` в React, миксин `}<inlineCode parentName="li">{`AdaptiveMixin`}</inlineCode>{` и компонент `}<inlineCode parentName="li">{`HseAdaptive`}</inlineCode>{` во Vue`}</li>
              <li parentName="ol"><a parentName="li" {...{
                  "href": "/src-components-blockquote"
                }}>{`Blockquote`}</a>{` и `}<a parentName="li" {...{
                  "href": "/src-components-heading"
                }}>{`Heading`}</a>{` теперь адаптируются автоматически под разрешение экрана`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Только в React`}
            <ol parentName="li">
              <li parentName="ol"><a parentName="li" {...{
                  "href": "/src-components-radio"
                }}>{`RadioGroup`}</a>{` - добавили неконтролируемое использование`}</li>
              <li parentName="ol">{`Доработали полиморфные компоненты`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Только во Vue`}
            <ol parentName="li">
              <li parentName="ol">{`Перенесли все компоненты на TypeScript. В TypeScript проектах вы можете использовать наши декларации типов`}</li>
              <li parentName="ol">
                <Link subdomain="vue-design" href="" mdxType="Link">Обновили сайт с документацией</Link>
              </li>
              <li parentName="ol">{`Добавили прокидывание аттрибутов на `}<inlineCode parentName="li">{`input`}</inlineCode>{` и добавили `}<inlineCode parentName="li">{`rootProps`}</inlineCode></li>
              <li parentName="ol">{`В CheckboxGroup и RadioGroup добавили `}<inlineCode parentName="li">{`options`}</inlineCode>{` и поддержку `}<inlineCode parentName="li">{`v-model`}</inlineCode></li>
              <li parentName="ol">{`Modal. Добавили пропы `}<inlineCode parentName="li">{`shouldCloseOnOverlayClick`}</inlineCode>{` для закрытия модального окна при клике на оверлей, `}<inlineCode parentName="li">{`shouldCloseOnEsc`}</inlineCode>{` для закрытия при нажатии на `}<inlineCode parentName="li">{`esc`}</inlineCode></li>
            </ol>
          </li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-badge-label"
            }}>{`BadgeLabel`}</a>{` добавили размер `}<inlineCode parentName="li">{`smallX`}</inlineCode></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-button"
            }}>{`Button`}</a>
            <ol parentName="li">
              <li parentName="ol">{`Обновили стили `}<inlineCode parentName="li">{`small`}</inlineCode>{` размера`}</li>
              <li parentName="ol">{`Добавили окрашивание `}<inlineCode parentName="li">{`tertiary`}</inlineCode>{` варианта`}</li>
              <li parentName="ol">{`Добавили `}<inlineCode parentName="li">{`secondary`}</inlineCode>{` `}<inlineCode parentName="li">{`selected`}</inlineCode>{` вариант`}</li>
            </ol>
          </li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-clickable-icon"
            }}>{`ClickableIcon`}</a>{` теперь полиморфный`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-collapse"
            }}>{`Collapse`}</a>{`. Добавили `}<inlineCode parentName="li">{`color`}</inlineCode>{` `}<inlineCode parentName="li">{`invert`}</inlineCode>{` для использования компонента на темном фоне`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/logo#%D1%84%D0%B0%D0%B2%D0%B8%D0%BA%D0%BE%D0%BD"
            }}>{`Favicon`}</a>{`. Доработали отображение в Safari`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-file-input"
            }}>{`FileInput`}</a>{`. Добавили проп `}<inlineCode parentName="li">{`error`}</inlineCode></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-footer"
            }}>{`Footer`}</a>{`. Доработали внешний вид`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-header"
            }}>{`HeaderHamburger`}</a>{`. Добавили проп `}<inlineCode parentName="li">{`isOpen`}</inlineCode>{` и анимацию изменения состояния`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-icon"
            }}>{`Icon`}</a>{`. Добавили проп `}<inlineCode parentName="li">{`color`}</inlineCode>{` для задания цвета иконки`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-icon-button"
            }}>{`IconButton`}</a>{`. Добавили `}<inlineCode parentName="li">{`secondary`}</inlineCode>{` вариант`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-input"
            }}>{`Input`}</a>{`, `}<a parentName="li" {...{
              "href": "/src-components-textarea"
            }}>{`TextArea`}</a>{`. Убрали браузерные autocomplete стили`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-input"
            }}>{`Input`}</a>
            <ol parentName="li">
              <li parentName="ol">{`Исправили отображение курсора для `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` состояния`}</li>
              <li parentName="ol">{`Исправили отображение `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` варианта в Safari`}</li>
              <li parentName="ol">{`Добавили смену размера иконки и ее отступов в зависимости от размера компонента`}</li>
            </ol>
          </li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-link"
            }}>{`Link`}</a>{`. Добавили `}<inlineCode parentName="li">{`tertiary`}</inlineCode>{` вариант`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-ul-ol-li"
            }}>{`List`}</a>{`. Доработали стили`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-rich-input"
            }}>{`RichInput`}</a>{`. Добавили проп `}<inlineCode parentName="li">{`hint`}</inlineCode>{` для отображения подсказки`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-select"
            }}>{`Select`}</a>
            <ol parentName="li">
              <li parentName="ol">{`Добавили проп `}<inlineCode parentName="li">{`error`}</inlineCode>{` для контроля над состоянием ошибки, `}<inlineCode parentName="li">{`selectedFirst`}</inlineCode>{` для отображения выбранных опций в начале списка`}</li>
              <li parentName="ol">{`Исправили отображение длинных названий опций. С помощью пропа `}<inlineCode parentName="li">{`lineClamp`}</inlineCode>{` вы можете регулировать максимальное количество строк для текста опции`}</li>
              <li parentName="ol">{`Добавили уведомление о дублирующихся опциях`}</li>
            </ol>
          </li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-sidebar"
            }}>{`Sidebar`}</a>
            <ol parentName="li">
              <li parentName="ol">{`Обновили внешний вид`}</li>
              <li parentName="ol">{`Добавили отображение названия при наведении`}</li>
              <li parentName="ol">{`Добавили схлопывание сайдбара при клике на пункт`}</li>
              <li parentName="ol">{`Добавили Вариант `}<inlineCode parentName="li">{`tight`}</inlineCode></li>
              <li parentName="ol">{`Добавили отображение бэйджей в закрытом состоянии`}</li>
              <li parentName="ol">{`Добавили пропы `}<inlineCode parentName="li">{`toggleText`}</inlineCode>{`, `}<inlineCode parentName="li">{`openText`}</inlineCode>{` для поддержки интернализации`}</li>
            </ol>
          </li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-table"
            }}>{`TableCell`}</a>{`. Добавили проп `}<inlineCode parentName="li">{`align`}</inlineCode>{` для горизонтального выравнивания текста`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-tap-bar"
            }}>{`TapBar`}</a>{`. Обновили отступы между кнопками`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-textarea"
            }}>{`TextArea`}</a>
            <ol parentName="li">
              <li parentName="ol">{`Добавили проп `}<inlineCode parentName="li">{`counter`}</inlineCode>{` для отображения текущего/оставшегося количества символов`}</li>
              <li parentName="ol">{`Добавили проп `}<inlineCode parentName="li">{`counterError`}</inlineCode>{` для сообщения об ошибке`}</li>
              <li parentName="ol">{`Убрали кастомный ресайзер из-за проблем с кроссбраузерностью`}</li>
            </ol>
          </li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-tooltip"
            }}>{`Tooltip`}</a>
            <ol parentName="li">
              <li parentName="ol">{`Добавили проп `}<inlineCode parentName="li">{`visible`}</inlineCode>{` для контролируемого отображения`}</li>
              <li parentName="ol">{`Добавили проп  `}<inlineCode parentName="li">{`closable`}</inlineCode>{` для добавления возможности скрытия компонента`}</li>
              <li parentName="ol">{`Обновили отступы`}</li>
            </ol>
          </li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-modal"
            }}>{`ModalForm`}</a>{`. Убрали минимальную высоту`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-popover"
            }}>{`Popover`}</a>{`. Добавили клавиатурную навигацию`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-radio"
            }}>{`RadioGroup`}</a>{`. Добавили горизонтальный вариант отображения`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-tag"
            }}>{`Tag`}</a>{`. Добавили `}<inlineCode parentName="li">{`size`}</inlineCode>{` `}<inlineCode parentName="li">{`large`}</inlineCode>{` и `}<inlineCode parentName="li">{`variant`}</inlineCode>{` `}<inlineCode parentName="li">{`secondaryInvert`}</inlineCode></li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Багфиксы`}</p>
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-select"
            }}>{`Select`}</a>{`. Отрефакторили компонент и исправили баги`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/src-components-segmented-control"
            }}>{`SegmentedControl`}</a>{`. Исправили выделение выбранного по умолчанию сегмента`}</li>
          <li parentName="ol">{`Vue. исправили `}<inlineCode parentName="li">{`v-model`}</inlineCode>{` в TextArea`}</li>
        </ol>
      </li>
    </ol>
    <h2 {...{
      "id": "v110---02122020"
    }}>{`v1.1.0 - `}<inlineCode parentName="h2">{`02.12.2020`}</inlineCode></h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Новые компоненты: `}<a parentName="p" {...{
            "href": "/src-components-file-input"
          }}>{`FileInput`}</a>{`, `}<a parentName="p" {...{
            "href": "/src-components-file-button"
          }}>{`FileButton`}</a>{`, `}<a parentName="p" {...{
            "href": "/src-components-sidebar"
          }}>{`Sidebar`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Улучшения компонента `}<a parentName="p" {...{
            "href": "/src-components-collapse"
          }}>{`Collapse`}</a>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Возможность контролируемого использования через пропы `}<inlineCode parentName="li">{`isOpen`}</inlineCode>{` и `}<inlineCode parentName="li">{`onToggle`}</inlineCode></li>
          <li parentName="ul">{`Новый проп `}<inlineCode parentName="li">{`defaultOpen`}</inlineCode>{` для состояния открытости по-умолчанию`}</li>
          <li parentName="ul">{`Новый проп `}<inlineCode parentName="li">{`variant`}</inlineCode>{`, позволяющий использовать контентный вариант коллапса`}</li>
          <li parentName="ul">{`Компонент `}<inlineCode parentName="li">{`ContentCollapse`}</inlineCode>{` теперь использует контентный вариант коллапса`}</li>
          <li parentName="ul">{`Новый проп `}<inlineCode parentName="li">{`classes`}</inlineCode>{` для передачи дополнительных css-классов элементов`}</li>
          <li parentName="ul">{`Новый проп `}<inlineCode parentName="li">{`children`}</inlineCode>{` для передачи скрытого контента коллапса с поддержкой jsx-элементов`}</li>
          <li parentName="ul">{`Проп `}<inlineCode parentName="li">{`content`}</inlineCode>{` помечен как устаревший, используйте `}<inlineCode parentName="li">{`children`}</inlineCode></li>
          <li parentName="ul">{`Проп `}<inlineCode parentName="li">{`onChange`}</inlineCode>{` помечен как устаревший, используйте `}<inlineCode parentName="li">{`onToggle`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`В список токенов `}<inlineCode parentName="p">{`sidebarWidth`}</inlineCode>{` добавлены новые токены `}<inlineCode parentName="p">{`sidebar-width-*-static`}</inlineCode>{`, обозначающие
ширину статического сайдбара на брейкпоинтах от `}<inlineCode parentName="p">{`tablet-large`}</inlineCode>{` и выше`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Улучшения компонента `}<a parentName="p" {...{
            "href": "/src-components-badge-label"
          }}>{`BadgeLabel`}</a>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Компонент теперь рендерится со свойствами `}<inlineCode parentName="li">{`min-width: fit-content`}</inlineCode>{` и `}<inlineCode parentName="li">{`white-space: nowrap`}</inlineCode>{`,
что позволяет предотвратить слишком сильное ужимание компонента под влиянием соседей`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Изменения компонента `}<a parentName="p" {...{
            "href": "/src-components-header"
          }}>{`Header`}</a>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Теперь внутренний ClickableIcon для логотипа не рендерится, если не передавать проп `}<inlineCode parentName="li">{`logoIcon`}</inlineCode></li>
          <li parentName="ul">{`Теперь внутренний HeaderHamburger рендерится только если проп `}<inlineCode parentName="li">{`right`}</inlineCode>{` не передан.
Если в него передать `}<inlineCode parentName="li">{`false`}</inlineCode>{`, то гамбургер не будет отображен`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`В пакет `}<inlineCode parentName="p">{`@hse-design/core`}</inlineCode>{` добавлено новое перечисление `}<inlineCode parentName="p">{`Breakpoint`}</inlineCode>{` и соответствующие константы:
`}<inlineCode parentName="p">{`breakpointWidth`}</inlineCode>{`, `}<inlineCode parentName="p">{`breakpointsList`}</inlineCode>{`, `}<inlineCode parentName="p">{`breakpointsOrder`}</inlineCode>{`; и утилиты: `}<inlineCode parentName="p">{`getBreakpoint`}</inlineCode>{`, `}<inlineCode parentName="p">{`getWindowBreakpoint`}</inlineCode>{`, `}<inlineCode parentName="p">{`isBreakpointLess`}</inlineCode>{`.
Подробнее см. в файле `}<a parentName="p" {...{
            "href": "https://gitlab.com/proscom/hse-design/-/blob/57ae1c09e529074ae3fb8606cbd6bb60a87e5b05/packages/core/src/utils/breakpoints.ts"
          }}>{`packages/core/src/utils/breakpoints.ts`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Из файлов `}<a parentName="p" {...{
            "href": "https://gitlab.com/proscom/hse-design/-/merge_requests/160/diffs#diff-content-9e48bfc633f07dfc5313c62b4301a00fc179224a"
          }}>{`@hse-design/core/src/base.scss`}</a>{`
и `}<a parentName="p" {...{
            "href": "https://gitlab.com/proscom/hse-design/-/merge_requests/160/diffs#diff-content-c746e4eb74be54bff854c25c0d2424926b690a9b"
          }}>{`@hse-design/core/src/z-index.scss`}</a>{` теперь экспортированы некоторые scss переменные.
Это позволяет импортировать их в JavaScript при сборке проекта через Webpack.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Из пакета `}<inlineCode parentName="p">{`@hse-design/react`}</inlineCode>{` экспортированы утилитарные хуки, использующиеся внутри ДС. Они могут быть полезны
при разработке продуктов. Более подробная документация по ним скоро появится на сайте ДС.
Пока список хуков и документацию к ним можно посмотреть в `}<a parentName="p" {...{
            "href": "https://gitlab.com/proscom/hse-design/-/tree/2774d5609ee621014bc1b2a25d43b5e49d8a5dd8/packages/react/src/common/hooks"
          }}>{`папке в репозитории`}</a>{`.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "v103---02122020"
    }}>{`v1.0.3 - `}<inlineCode parentName="h2">{`02.12.2020`}</inlineCode></h2>
    <ul>
      <li parentName="ul">{`Исправлен список файлов, публикующихся в пакетах `}<inlineCode parentName="li">{`@hse-design/tokens`}</inlineCode>{` и `}<inlineCode parentName="li">{`@hse-design/vue`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "v102---10112020"
    }}>{`v1.0.2 - `}<inlineCode parentName="h2">{`10.11.2020`}</inlineCode></h2>
    <ul>
      <li parentName="ul">{`Исправлена механика импорта статических ассетов в `}<inlineCode parentName="li">{`@hse-design/core`}</inlineCode>{`, чтобы проект собирался с использованием Parcel`}</li>
    </ul>
    <h2 {...{
      "id": "v101---10112020"
    }}>{`v1.0.1 - `}<inlineCode parentName="h2">{`10.11.2020`}</inlineCode></h2>
    <ul>
      <li parentName="ul">{`Удалён неправильный вариант логотипа ВШЭ. Компонент Logo помечен как устаревший. Соответствующие статьи в документации убраны.`}</li>
    </ul>
    <h2 {...{
      "id": "v100---10112020"
    }}>{`v1.0.0 - `}<inlineCode parentName="h2">{`10.11.2020`}</inlineCode></h2>
    <p>{`Мы рады представить вам первую стабильную версию Дизайн-Системы.
Мы не прекращаем свою работу и продолжим дополнять Дизайн-Систему новыми компонентам и функциями.`}</p>
    <p>{`Начиная с этого релиза, все изменения, входящие в последующие релизы, будут публиковаться на этой странице.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      